import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { isStaffUser } from '../contexts/auth';
import { navigate } from 'gatsby';

import '../style/page.scss';
import Page from '../components/page';

// Check if window is defined (so if in the browser or in node.js). Production build fails if not checking
const isBrowser = typeof window !== 'undefined';

const UserManual = (props: any) => {
  const isStaff = isStaffUser();

  // Redirect to home if user is not staff
  if (!isStaff && isBrowser) {
    navigate('/');
  }

  return (
    <Page isFetching={false} container={true}>
      <Box py={4}>
        <Typography variant="body1" align="center">
          A user guide for the new ORS will be on this page in the near future
        </Typography>
      </Box>
    </Page>
  );
};

export default UserManual;
